import React from "react"
import LayoutContainer from "../components/layout"
import { Trans, withTranslation } from "react-i18next"

const PrivacyPolicy = () => {
  return (
    <LayoutContainer seoTitle="Privacy policy">
      <article>
        <div className="row">
          <div className="col l8 offset-l2 s12">
            <h1 className="big-title uppercase center-align">
              <Trans i18nKey={"privaypolicy.title"} />
            </h1>
            <p className="text">
              <Trans i18nKey={"privaypolicy.text_first"} />
              <br />
              <br />
              <Trans i18nKey={"privaypolicy.text_second"} />
              <br />
              <br />
              <Trans i18nKey={"privaypolicy.text_third"} />
              <br />
              <br />
              <Trans i18nKey={"privaypolicy.text_frouth"} />
              <br />
              <br />
              <Trans i18nKey={"privaypolicy.text_fifth"} />
            </p>
          </div>
        </div>
      </article>
    </LayoutContainer>
  )
}

export default withTranslation()(PrivacyPolicy)
